<template>
  <div>
    <h2>
      <b-badge>Authors</b-badge>
    </h2>
    <b-container>
      <b-row align-h="end">
        <b-col cols="1"><b-button href="#/authorEdit">Add</b-button></b-col>
      </b-row>
    </b-container>
    <b-table class="myarticle-table" striped hover :items="items" :fields="fields">
      <template #cell(remove)="row">
        <b-button variant="danger" @click="removeApp(row.item.id)">Delete</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'
// import json from './testdata.json'
export default {

  data() {
    return {
      fields: [
        {key: 'firstName', label: 'First name'},
        {key: 'lastName', label: 'Last name'},
        {key: 'remove', label: ''},
      ],
      items: []
    }
  },
  methods: {
    addApp: function() {
      alert("save");
    },
    removeApp: function(doi) {
      alert("delete " + doi);
    }
  },
  mounted() {
    // this.items = json
    axios
        .get('/authors')
        .then(response => {
          this.items = response.data
        })
  }
}
</script>
<style>
.myarticle-table {
  margin-top: 20px;
}
</style>

