<template>
  <div>

    <b-alert
        :show="dismissCountDown"
        dismissible
        :variant="responseMessage.style"
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
    >
      {{ responseMessage.message }}
    </b-alert>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-container fluid="fluid">
          <b-row align-h="end">
            <b-col cols="2">
              <b-button-group>
                <b-button type="submit" :disabled="saveDisabled">Save</b-button>
                <b-button href="#/">Back</b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-container>
        <fieldset>
          <legend>
            Journal
          </legend>
          <b-container fluid="fluid">
            <b-row>
              <b-col>Publisher Name
                <b-form-input v-model="article.publisherName" disabled="disabled"/>
              </b-col>
              <b-col>Journal Title
                <b-form-input v-model="article.journalTitle" disabled="disabled"/>
              </b-col>
              <b-col>ISSN
                <b-form-input v-model="article.issn" disabled="disabled"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col>Article Title English
                <validation-provider name="Article Title English" :rules="{ required: true }"
                                     v-slot="validationContext">
                  <b-form-input v-model="article.title"
                                id="articleTitleEnglish"
                                :state="getValidationState(validationContext)"
                                placeholder="Title"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                Volume
                <validation-provider name="Volume" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-input v-model="article.volume"
                                id="volume"
                                :state="getValidationState(validationContext)"
                                placeholder="Volume. E.g. 2"
                                aria-describedby="input-mandatory-feedback"
                                type="number">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                Issue
                <validation-provider name="Issue" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-input v-model="article.issue"
                                id="issue"
                                :state="getValidationState(validationContext)"
                                placeholder="Issue. E.g. 3"
                                aria-describedby="input-mandatory-feedback"
                                type="number">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>Article Title German
                <validation-provider name="Article Title German" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-input v-model="article.titleGerman"
                                id="" titleGerman
                                :state="getValidationState(validationContext)"
                                placeholder="Title"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                <b-container>
                  <b-row>
                    <b-col style="padding-left:0px">
                      Publish Year
                      <validation-provider name="Publish Year"
                                           :rules="{ required: true, min_value:1900, max_value:2030 }"
                                           v-slot="validationContext">
                        <b-form-input v-model="article.pubYear"
                                      :state="getValidationState(validationContext)"
                                      placeholder="YYYY. e.g. 2021"
                                      aria-describedby="input-year-feedback"
                                      type="number">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-mandatory-feedback">{{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                    <b-col style="padding-right: 0px">
                      Publish Month
                      <validation-provider name="Publish Month" :rules="{ required: true, min_value:1, max_value:12 }"
                                           v-slot="validationContext">
                        <b-form-input v-model="article.pubMonth"
                                      :state="getValidationState(validationContext)"
                                      placeholder="MM. E.g. 12"
                                      aria-describedby="input-month-feedback"
                                      type="number">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-mandatory-feedback">{{
                            validationContext.errors[0]
                          }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
              <b-col>DOI
                <validation-provider name="DOI" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-input v-model="article.doi"
                                :state="getValidationState(validationContext)"
                                placeholder="DOI"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                Status
                <validation-provider name="Status" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-select v-model="article.status"
                                 :state="getValidationState(validationContext)"
                                 aria-describedby="input-mandatory-feedback">
                    <b-form-select-option value="ppublish">Paper publish</b-form-select-option>
                    <b-form-select-option value="epublish">Electronic publish</b-form-select-option>
                    <b-form-select-option value="aheadofprint">Ahead of print</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
          </b-container>
        </fieldset>

        <fieldset>
          <legend>
            Authors
          </legend>
          <b-container fluid="fluid" v-for="(author, index) in article.authors" :key="author.id">
            <b-button v-if="index > 0" @click="removeAuthor(index)">Remove</b-button>
            <b-row>
              <b-col>First Name
                <validation-provider name="First Name" :rules="{ required: true, min: 2 }"
                                     v-slot="validationContext">
                  <b-form-input v-model="author.firstName"
                                placeholder="First name"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>Middle Name
                <b-form-input v-model="author.middleName"
                              placeholder="Middle name">
                </b-form-input>
              </b-col>
              <b-col>Last Name
                <validation-provider name="Last Name" :rules="{ required: true, min: 2 }"
                                     v-slot="validationContext">
                  <b-form-input v-model="author.lastName"
                                placeholder="Last name"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                ORCID
                <validation-provider name="ORCID"
                                     :rules="{ required: false, regex:/^(\d{4}-\d{4}-\d{4}-\d{4})/ }"
                                     v-slot="validationContext">
                  <b-form-input v-model="author.orcid"
                                @keyup="formatorcid(author)"
                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                Affiliation
                <validation-provider name="Affiliation" :rules="{ required: true, min: 2 }"
                                     v-slot="validationContext">
                  <b-form-textarea
                      v-model="author.affiliation"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="6"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-mandatory-feedback"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          </b-container>
          <b-button variant="success" style="margin: 10px 0 0 15px" @click="addAuthor">Add Author</b-button>
        </fieldset>


        <fieldset>
          <legend>
            Abstracts
          </legend>
          <b-container fluid>
            <b-row>
              <b-col>
                Abstract German
                <validation-provider name="Abstract German" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-textarea
                      v-model="article.abstractGerman"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="6"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-mandatory-feedback"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          </b-container>
          <b-container fluid>
            <b-row>
              <b-col>
                Abstract English
                <validation-provider name="Abstract English" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-textarea
                      v-model="article.abstractEnglish"
                      placeholder="Enter something..."
                      rows="3"
                      max-rows="6"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-mandatory-feedback"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
          </b-container>
        </fieldset>

        <fieldset>
          <legend>
            Article Details
          </legend>
          <b-container fluid="fluid">
            <b-row>
              <b-col>PMID
                <b-form-input v-model="article.pmid" disabled="disabled">
                </b-form-input>
              </b-col>
              <b-col>First Page
                <validation-provider name="First Page" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-input v-model="article.firstPage"
                                type="number"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>Last Page
                <validation-provider name="Last Page" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-input v-model="article.lastPage"
                                type="number"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-mandatory-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                Language
                <validation-provider name="Last Page" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-select v-model="article.language"
                                 :state="getValidationState(validationContext)">
                    <b-form-select-option value="EN">English</b-form-select-option>
                    <b-form-select-option value="DE">German</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                Publication Type
                <validation-provider name="Publication Type" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-select v-model="article.publicationType"
                                 :state="getValidationState(validationContext)" :options="options">
                  </b-form-select>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row>
              <b-col>
                Copyright Information
                <b-form-input v-model="article.copyright">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                COI Statement
                <b-form-input v-model="article.coiStatement">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row v-for="(keyword, index) in article.keywords" :key="index">
              <b-col>
                Keyword
                <validation-provider name="Keyword" :rules="{ required: true, min: 2}" v-slot="validationContext">
                  <b-form-input v-model="article.keywords[index]"
                                :state="getValidationState(validationContext)"></b-form-input>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col>
                <b-button @click="removeKeyword(index)" style="margin-top: 20px">Remove</b-button>
              </b-col>
            </b-row>
            <b-button variant="success" style="margin-top: 10px" @click="addKeyword">Add Keyword</b-button>
          </b-container>
        </fieldset>


        <fieldset>
          <legend>
            References
          </legend>
          <b-container fluid="fluid" v-for="(reference, index) in article.references" :key="reference.id">
            <b-button @click="removeReference(index)">Remove</b-button>
            <b-row>
              <b-col>
                Citation
                <validation-provider name="Citation" :rules="{ required: true}" v-slot="validationContext">
                  <b-form-input v-model="reference.citation"
                                :state="getValidationState(validationContext)"/>
                  <b-form-invalid-feedback id="input-mandatory-feedback">{{
                      validationContext.errors[0]
                    }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                PMID
                <b-form-input v-model="reference.pmid" />
              </b-col>
            </b-row>
          </b-container>
          <b-button variant="success" style="margin: 10px 0 0 15px" @click="addReference">Add Reference</b-button>


        </fieldset>


        <div style="margin-top: 40px"></div>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import Author from "../models/author"
import Reference from "../models/reference"
import publicationTypes from "./publicationType.json"
import emptyArticle from "./emptyArticle.json"
import articleService from "../services/article-service"
import ServerResponse from "../models/server-response";

export default {

  data() {
    return {
      saveDisabled: false,
      options: publicationTypes,
      article: emptyArticle,
      responseMessage: new ServerResponse(),
      dismissSecs: 5,
      dismissCountDown: 0,
    }
  },
  methods: {
    onSubmit() {
      this.saveDisabled = true;
      articleService.saveArticle(this.article).then(response => {
        console.log(response);
        this.responseMessage = new ServerResponse("Article stored successfully", response.status, "success");
        this.dismissCountDown = this.dismissSecs;
        this.article = response.data;
      }).catch(error => {
        this.responseMessage = new ServerResponse("Error storing article", error.status, "danger");
        this.dismissCountDown = this.dismissSecs;
        if (error.status === 401) {
          this.$store.dispatch("auth/logout");
        }
      }).finally(() => this.saveDisabled = false);
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    formatorcid(author) {
      if (author.orcid != null) {
        if (!author.orcid.endsWith('-')) {
          author.orcid = author.orcid.replaceAll('-', '');
          author.orcid = author.orcid.match(/.{1,4}/g).join('-');
        }
        if (author.orcid.length > 19) {
          author.orcid = author.orcid.substring(0, 19);
        }
      }
    },
    addAuthor() {
      this.article.authors.push(new Author());
    },
    removeAuthor(index) {
      this.article.authors.splice(index, 1);
    },
    removeReference(index) {
      this.article.references.splice(index, 1);
    },
    addReference() {
      this.article.references.push(new Reference());
    },
    removeKeyword(index) {
      this.article.keywords.splice(index, 1);
    },
    addKeyword() {
      this.article.keywords.push("");
    }
  },
  mounted() {
    this.article = this.$store.getters["articles/currentArticle"];
  }
}
</script>
<style>
fieldset {
  margin-top: 20px;
}
</style>

