<template>
  <div>
    <h2>
      <b-badge>Articles</b-badge>
    </h2>
    <b-container fluid="fluid">
      <b-row align-h="end">
        <b-col cols="1">
          <b-button @click="addArticle()" href="#/articleEdit">Add</b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-table id="articleTable" class="myarticle-table" striped hover :items="items" :fields="fields">
      <template #cell(edit)="row">
        <b-button id="editButton" variant="success" @click="editArticle(row.item)" href="#/articleEdit">Edit</b-button>
      </template>
      <template #cell(remove)="row">
        <b-button id="deleteButton" variant="danger" @click="removeArticle(row.item.id)">Delete</b-button>
      </template>
      <template #cell(export)="row">
        <b-button variant="primary" @click="exportArticle(row.item.id)">Export</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import emptyArticle from './emptyArticle.json';
import emptyAuthor from './emptyAuthor.json';
import articleService from '../services/article-service';

export default {

  data() {
    return {
      fields: [
        {key: 'doi', label: 'ArticleId'},
        {key: 'title', label: 'Title'},
        {key: 'edit', label: ''},
        {key: 'remove', label: ''},
        {key: 'export', label: ''},
      ],
      items: []
    }
  },
  methods: {
    addArticle: function () {
      let clonedObject = {...emptyArticle};
      clonedObject.keywords = [];
      clonedObject.authors[0] = {...emptyAuthor};
      clonedObject.references = [];
      this.$store.commit("articles/setArticle", clonedObject);
    },
    removeArticle: function (id) {
      if (confirm("Do you really want to delete?")) {
        articleService.deleteArticle(id).then(response => {
          console.log(response);
          articleService.getArticles().then(res => {
            this.items = res.data;
          })
        }).catch(error => {
          if (error.response.status === 401) {
            this.logoutUser()
          }
        });
      }
    },
    exportArticle: function (id) {
      articleService.exportXml(id);
    },
    editArticle: function (article) {
      this.$store.commit("articles/setArticle", article);
    },
    logoutUser: function() {
      console.log("User not authenticated. Will forward to login page")
      this.$store.dispatch("auth/logout");
      this.$router.push("/login")
    }
  },
  mounted() {
    articleService.getArticles().then(response => {
      this.items = response.data;
    }).catch(error => {
      if (error.response.status === 401) {
        this.logoutUser()
      }
    });
  }
}
</script>
<style>
.myarticle-table {
  margin-top: 20px;
}
</style>

