import axios from 'axios';
import authHeader from "@/app/services/auth-header";

const API_URL = '/v1/articles/';

class ArticleService {
    saveArticle(article) {
        return axios
            .post(API_URL, article, {headers: authHeader()});
    }

    deleteArticle(id) {
        return axios
            .delete(API_URL + id, {headers: authHeader()});
    }

    getArticles() {
        return axios
            .get(API_URL, {headers: authHeader()});
    }

    exportXml(id) {
        axios.get(API_URL + id + "/export", {responseType: 'blob'})
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/xml'})
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = "article.xml"
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
    }
}

export default new ArticleService();