<template>
  <div class="myarticle-navbar">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="#">Hallenberger Media Journal management</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#/">Journal Articles</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-button v-if="showUser">
            <b-icon icon="person-fill"></b-icon>
            {{ username }}
          </b-button>
          <b-nav-item href="#/login" @click="handleLogout">Logout</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {

  data() {
    return {
      items: []

    }
  },
  computed: {
    showUser: function () {
      return this.$store.state.auth.status.loggedIn
    },
    username: function() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user.userName;
      } else {
        return "";
      }
    }
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("auth/logout");
    }
  }
}
</script>
<style>
.myarticle-navbar {
margin-bottom: 20px; /* Remove margins */
}
</style>

