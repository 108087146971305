<template>
  <div id="app">
    <NavBar/>
    <router-view class="router-view"></router-view>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'Journal Manager'
  },
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
.router-view {
  margin: 0 5px 0 5px
}
</style>
