export const articles = {
    namespaced: true,
    state: {
        article: null
    },
    getters: {
        currentArticle: state => {
            return state.article
        }
    },
    mutations: {
        setArticle (state, article) {
            state.article = article
        }
    },
    actions: {}
};