<template>
  <b-container>
    <b-row class="justify-content-md-center mt-4">
      <b-col col md="4">
        <b-card
            title="Login"
            class="mb-2">
          <b-card-text>
            <b-form @submit="onSubmit">
              <b-alert show variant="danger" v-if="message">{{ message }}</b-alert>
              Username
              <b-form-input v-model="user.userName"/>
              Password
              <b-form-input v-model="user.password" type="password"/>
              <b-button type="submit" variant="secondary" style="margin-top: 10px">
                <b-spinner v-show="loading" label="Spinning"></b-spinner>
                <span>Login</span>
              </b-button>
            </b-form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import User from '../models/user'

export default {

  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      if (this.user.userName && this.user.password) {
        this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/');
            },
            error => {
              this.loading = false;
              this.message =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();
            }
        );
      }
    }
  }
}

</script>