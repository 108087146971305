import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import {auth} from './auth.module';
import {articles} from './articles.module'


export default new Vuex.Store({
    modules: {
        auth,
        articles
    }
});